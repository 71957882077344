<template>
  <div class="text-container">
    <p>
      Für Sie überprüfen wir anhand der BWA jede Kostenart. Hier findet
      insbesondere eine Überprüfung der Personalkosten auf Basis
      Soll-/Ist-Vergleich statt, sowie die Überprüfung aller bestehenden
      Verträge. Für nahezu jede Art, der bei Ihnen anfallenden Kosten, gibt es
      Alternativen und Möglichkeiten zur Reduktion. Wir zeigen diese auf und
      verbessern damit Ihr Ergebnis.
    </p>
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Für Sie überprüfen wir anhand der BWA jede Kostenart Ihrer Apotheke. Wir zeigen Alternativen für Kosten und Möglichkeiten zur Reduktion.",
      },
    ],
  },
};
</script>